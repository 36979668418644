import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import styles from './category-switch.module.scss'


export const CategorySwitch = ({ categories }) => (
   <div className={styles.CategorySwitch}>
            {categories.map(cat => 
      <Link
         key={cat.slug}
         className={cat.active? styles.active : undefined}
         to={cat.to}
      >
         {cat.title}
      </Link>)}

   </div>
)