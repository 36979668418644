import React from 'react'
import { Helmet } from 'react-helmet-async'
import { getStaticImage } from '../data/files'

export const OgTags = ({title, description}) => {
   const HOSTNAME = 'https://chindermusigwaelt.swissmom.ch'

   const pageTitle = `Chinder Musig Wält — ${title}`
   const ogImageSource = getStaticImage('elements/general/cmw-og-image')[0]
   return (
      <Helmet>
         <title>{pageTitle}</title>
         <meta name="description" content={description} />

         <meta property="og:title" content={pageTitle} />
         {description && <meta property="og:description" content={description} />}
         <meta property="og:site_name" content="Chinder Musig Wält" />
         <meta property="og:image" content={HOSTNAME+ogImageSource} />
      </Helmet>
   )
}