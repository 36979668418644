import React from 'react'
import ReactDOM from 'react-dom'
import './styles/main.scss'
import App from './App.jsx'

const rootElement = document.getElementById("root")
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement)
} else {
  ReactDOM.render(<App />, rootElement)
}