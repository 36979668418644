import React from 'react'
import { Link } from 'react-router-dom'
import styles from './buttons.module.scss'
import { Image } from './image'
import { getStaticImage } from '../data/files'


export const Button = ({ text, imageName, to, linkParams}) => (
   <Link className={styles.button}
      to={to} {...linkParams}>
      <Image srcList={getStaticImage('elements/buttons/'+imageName)} alt={text}/>
      <span>{text}</span>
   </Link>
)

export const ButtonItunes = () => (
   <Button text={'Kinderlieder auf iTunes'}
      to={{pathname: 'https://itunes.apple.com/ch/album/chinder-musig-w%C3%A4lt-vol-1/1451412511'}}
      imageName='btn-itunes'
      linkParams={{target: '_blank', rel: 'noreferrer'}}
   />
)

export const ButtonYoutube = () => (
   <Button text={'Kinderlieder auf Youtube'}
      to={{pathname: 'https://www.youtube.com/watch?v=hEeTkSycTbk&list=PLuCGhcrgSs9irZxUfW9PixWTnDcW3B6ED&ab_channel=ChinderMusigW%C3%A4lt'}}
      imageName='btn-youtube'
      linkParams={{target: '_blank', rel: 'noreferrer'}}
   />
)

export const ButtonSpotify = () => (
   <Button text={'Kinderlieder auf Spotify'}
      to={{pathname: 'https://open.spotify.com/album/1UfyJX2ceaSkpjSMLaD57Z'}}
      imageName='btn-spotify'
      linkParams={{target: '_blank', rel: 'noreferrer'}}
   />
)





export const ButtonMusicSheet = ({to}) => (
   <Button text={'Noten zum Ausdrucken'}
      imageName='btn-noten'
      to={to}
      linkParams={{target: '_blank'}}
   />
)

export const ButtonAllSongs = () => (
   <Button text={'Alle Liedertexte'}
      imageName='btn-alle-lieder'
      to="/liedertexte"
   />
)

export const ButtonVideos = () => (
   <Button text={'Lieder mit Videos'}
      imageName='btn-videos'
      to="/videos"
   />
)

export const ButtonColoring = () => (
   <Button text={'Malvorlagen'}
      imageName='btn-malvorlagen'
      to="/malvorlagen"
   />
)

export const ButtonRhymes = () => (
   <Button text={'Reime und Verse'}
      imageName='btn-reime'
      to="/reime-verse"
   />
)

export const ButtonBlock = ({children}) => (
   <div className={styles.buttonblock}>
      {children}
   </div>
)

export const NaviButtonBlock = () => (
   <div className={styles.block}>
      <ButtonBlock>
         <ButtonAllSongs />
         <ButtonVideos />
         <ButtonColoring />
         <ButtonRhymes />
      </ButtonBlock>
   </div>
)

