import React, { useState } from 'react'

// data
import songs from './data/songs.json'
import songCategories from './data/songcategories.json'
import rhymes from './data/rhymes.json'
import coloringSheets from './data/coloring-sheets.json'

import {
   getColoringSheet,
   getMusicSheet,
   getStaticImage
} from './data/files'

import List from './components/list'
import { ThumbnailContainer } from './components/thumbnail'
import { SongHeader, SectionHeader } from './components/header'
import { Intro } from './components/intro'
import { ItemPage, PrevNext } from './components/item-page'
import {
   ButtonAllSongs,
   ButtonBlock,
   ButtonColoring,
   ButtonYoutube,
   ButtonSpotify, 
   ButtonItunes, 
   ButtonMusicSheet,
   ButtonVideos,
   NaviButtonBlock} from './components/buttons'
import { useHistory, useParams } from 'react-router-dom'

import Layout from './components/layout'
import { OgTags } from './components/og-tags'
import { CategorySwitch } from './components/category-switch'


const selectRandomItem = (list) => {
   return list[Math.floor(Math.random()*list.length)]
}

const songsWithVideos = songs.filter(song => song.video_url)

const getVideos = (selection) => {
   return selection.map(song => ({
      title: song.title,
      src: getStaticImage('lieder-thumbnails/'+song.slug),
      to: `/lieder/${song.slug}`
   }))
}

export const Home = () =>  {
   const randomSongWithVideo = selectRandomItem(songsWithVideos)

   return (
      <Layout>
         <OgTags 
            title="Die schönsten Schweizer Kinderlieder" 
            description="Entdecken Sie mit Ihren Kindern längst vergessene Liedtexte und Versli, frisch arrangierte Kinderlieder und Videos, die herzerwärmende Geschichten erzählen." 
         />
         <SongHeader slug={randomSongWithVideo.slug} 
            video={randomSongWithVideo.video_url}
         />
         <Intro />
         <ThumbnailContainer 
            title='Lieder mit Videos'
            items={getVideos(songsWithVideos)}
            orient='horizontal'
         />
         <NaviButtonBlock />
      </Layout>
   )
}


export const SongCategoryPage = () => {
   const {songCategory} = useParams()

   const categories = songCategories.map(cat => ({
      ...cat,
      to: '/liedertexte' + (cat.slug? `/${cat.slug}` : ''),
      active: cat.slug === (songCategory || "")
   }))

   const activeCategory = categories.filter(cat => cat.slug === songCategory)[0]
   const activeCategoryTitle = activeCategory? activeCategory.title : undefined

   const filteredSongs = activeCategoryTitle ? 
       songs.filter(song => (song.categories || []).includes(activeCategoryTitle))
       : songs

   return (
      <Layout>
         <OgTags 
            title={activeCategoryTitle? activeCategoryTitle : "Kinderlieder mit Texten"} 
            description="Mehr als 500 Kinderlieder mit Texten." 
         />
         <SectionHeader imagePath="section-lieder" />
         
         <List 
            header={<CategorySwitch categories={categories} />}
            markerFunc="firstletter"
            items={filteredSongs.map(item => ({
               ...item,
               to: '/lieder/'+ item.slug
            }))}
         />
      </Layout>
   )
}

export const VideosOverviewPage = () => (
   <Layout>
      <OgTags 
         title="Kinderlieder mit Videos" 
         description="Diese liebevoll und kindgerecht gestaltete Welt erweckt das Schweizer Liedgut zu neuem Leben." 
      />
      <SectionHeader imagePath="section-videos" />
      <ThumbnailContainer 
         items={getVideos(songsWithVideos)}
         orient='horizontal'
         title='Lieder mit Videos'
      />
   </Layout>
)

export const RhymesOverviewPage = () => (
   <Layout>
      <OgTags 
         title="Kinderreime und Fingerversli" 
         description="Entdecken Sie mit Ihren Kindern längst vergessene Liedtexte und Versli." 
      />
      <SectionHeader imagePath="section-reime-verse" />
      <List 
         title="Kinderreime und Fingerversli" 
         markerFunc="firstletter"
         items={rhymes.map(item => ({
            ...item,
            to: "/reime-verse/"+item.slug
         }))}
      />
   </Layout>
)

export const ColoringSheetsOverviewPage = () => (
   <Layout>
      <OgTags 
         title="Malvorlagen" 
         description="Liebevoll gestaltete Figuren aus der ChinderMusigWält als kostenlose Malvorlagen zum Ausdrucken." 
      />
      <SectionHeader imagePath="section-malvorlagen" />
      <ThumbnailContainer 
         items={coloringSheets.map(({title}) => ({
            title: title,
            src: getStaticImage(`malvorlagen-thumbnails/${title}`),
            to: getColoringSheet(title)
         }))}
         orient='vertical'
         linkParams={{target:'_blank'}}
         title='Malvorlagen'
      />
   </Layout>
)

export const MusicSheetsOverviewPage = () => {
   <OgTags 
      title="Noten" 
      description="Alle Noten als PDF zum Downloaden." 
   />
   const musicSheets = songs.filter(song => !!song.musicSheet)
      .map(song => ({
         title: song.title,
         to: getMusicSheet(song.slug),
      }))
   return (
      <Layout>
         <SectionHeader imagePath="section-noten" />
         <List 
            items={musicSheets} 
            title="Alle Noten zum Download" 
            linkParams= {{target: "_blank"}}
         />
      </Layout>
   )
}

const useCurrentItem = (items) => {
   const {slug} = useParams()
   return items.find(item => item.slug === slug)
}

const usePrevNext = (items) => {
   const [previous, setPrevious] = useState([])
   const history = useHistory()

   const goBack = () => {
      history.push(previous.slice(-1)[0])
      setPrevious(previous.slice(0, -1))
   } 

   const playPrev = previous.length > 0 ? goBack : undefined

   const playNext = () => {
      setPrevious([...previous, history.location])
      history.push(selectRandomItem(items).slug)
   }
   
   return [playPrev, playNext]
}

export const SongPage = () => {
   const song = useCurrentItem(songs)
   const [playPrev, playNext] = usePrevNext(songsWithVideos)

   return (
      <Layout>
         <OgTags 
            title={song.title}
         />
         <ItemPage 
            header={
               <SongHeader 
                  slug={song && song.slug} 
                  video={song && song.video_url} 
                  playNext={playNext}
               /> }
            prevNext={ <PrevNext 
                        prevText='vorheriges Lied'
                        nextText='nächstes Lied'
                        onPlayPrev={playPrev} 
                        onPlayNext={playNext}/> }
            content={song? song.content : "Lied nicht gefunden"}
            buttons={
               <ButtonBlock>
                  <ButtonYoutube />
                  <ButtonSpotify />
                  <ButtonItunes />
                  {song && song.musicSheet && 
                  <ButtonMusicSheet to={getMusicSheet(song.slug)}/>}                  
               </ButtonBlock>
            }
         />
      </Layout>
   )
}


export const RhymePage = () => {
   const rhyme = useCurrentItem(rhymes)
   const [playPrev, playNext] = usePrevNext(rhymes)

   return (
      <Layout>
         <OgTags 
            title={rhyme.title}
         />
         <ItemPage 
            header={
               <SectionHeader imagePath="section-reime-verse" />
            }
            prevNext={ <PrevNext 
                        prevText='vorheriger Reim'
                        nextText='nächster Reim'
                        onPlayPrev={playPrev} 
                        onPlayNext={playNext}/> }
            content={rhyme? rhyme.content : "Reim nicht gefunden"}
            buttons={
               <ButtonBlock>
                  <ButtonVideos />
                  <ButtonAllSongs />
                  <ButtonColoring />
               </ButtonBlock>
            }
         />
      </Layout>
   )
}

export const NotFound = () => (
   <Layout>
      <OgTags 
         title={"404 - Seite nicht gefunden"}
      />
      <ItemPage 
         content={"Seite nicht gefunden"}
         buttons={
            <ButtonBlock>
               <ButtonVideos />
               <ButtonAllSongs />
               <ButtonColoring />
            </ButtonBlock>
         }
      />
   </Layout>
)


