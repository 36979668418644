import React from 'react'
import { ButtonAllSongs } from './buttons'
import styles from './intro.module.scss'


export const Intro = () => (
   <div className={styles.intro}>
      <h1>Die schönsten Schweizer Kinderlieder</h1>
      <p>Begleiten Sie uns durch die neue «ChinderMusigWält»! 
         Diese liebevoll und kindgerecht gestaltete Welt erweckt das 
         Schweizer Liedgut zu neuem Leben. 
         Modern und fröhlich in Szene gesetzt begeistern die 
         Kinderlieder Gross und Klein. Entdecken Sie mit Ihren Kindern 
         längst vergessene Liedtexte und Versli, 
         frisch arrangierte Kinderlieder und Videos, 
         die herzerwärmende Geschichten erzählen.</p>
      <ButtonAllSongs />
   </div>
)