import React from 'react'
import styles from './item-page.module.scss'

import { Image } from './image'
import { getStaticImage } from '../data/files'


export const PrevNext = ({prevText, nextText, onPlayPrev, onPlayNext}) => (
   <div className={styles.prevnext}>
      {onPlayPrev && <button className={styles.prev} onClick={onPlayPrev}>
         <Image srcList={getStaticImage('elements/buttons/prev')} alt={prevText}/>
         <span>{prevText}</span>
      </button>
      }
      <button className={styles.next} onClick={onPlayNext}>
         <span>{nextText}</span>
         <Image srcList={getStaticImage('elements/buttons/next')} alt={nextText}/>
      </button>
   </div>
)

export const ItemPage = ({header, prevNext, content, buttons}) => {
   
   return (
      <>
         {header}
         <div className={styles.page}>
            {prevNext}
            { content ?  
               <div 
                  className={styles.content} 
                  dangerouslySetInnerHTML={{ __html: content }} 
               />
               : <div>404</div>
            }
            <div className={styles.buttons}>
               {buttons}
            </div>
         </div>
         
      </>
   )
}

export default ItemPage
