import React from 'react'

export const Image = ({src, srcList, alt, className}) => {
   if (!(src || (srcList && srcList.length > 0))) {
      return null
   }
   return (
      <picture>
         {srcList.map((item,index) => 
         <source key={index} srcSet={item} />)}
         <img src={src || srcList[0]} alt={alt} className={className} />
      </picture>
   )
}