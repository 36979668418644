import React, {useEffect} from 'react'
import styles from './ads.module.scss'

export const useRefreshAds = () => {
   useEffect(() => {
      try { window.audienzz.refreshAds() } catch (e) {}
   })
   return null
}

/* 
   CMW adTagId: 137
   adnz_wideboard_1
   adnz_wideboard_2
   adnz_halfpage_1

   <script src={`https://adnz.co/header.js?adTagId=${adTagId}`} />
*/

export const Ad = ({id}) => (
   <div id={id} />
)

export const Wideboard = ({id}) => (
   <div id={id} className={styles.Wideboard} />
)
