import React from 'react'
import { getStaticImage } from '../data/files'

import styles from './about.module.scss'
import { Image } from './image'
import Layout from './layout'


const PartnerTile = ({title, text, logoPath}) => (
   <div className={styles.partnerTile}>
      <Image srcList={getStaticImage('elements/about/'+logoPath)} alt={title} />
      <h3>{title}</h3>
      <p>{text}</p>
   </div>
)

export const About = () => (
   <Layout>
      <div className={styles.about}>
         <h1>Über uns</h1>
         <p>Wir danken unseren Partnern herzlich für die kreative und 
            inspirierende Zusammenarbeit und die erstklassige Unterstützung
            bei diesem Herzens-Projekt.
         </p>
         <div className={styles.partners}>
            <PartnerTile 
               title="VoiceSteps"
               text="In der Musicalschule VoiceSteps werden über 200 Kinder, 
               Jugendliche und junge Erwachsene von 5 bis 25 Jahren in 
               vier Altersabteilungen wöchentlich von einem professionellen
               Team von Fachpersonen aus dem Musicalbereich unterrichtet."
               logoPath="voicesteps"
            />
            <PartnerTile 
               title="illugraphics"
               text="Was gibt es Schöneres, als wenn man staunende Augen von 
               Kindern oder Erwachsenen leuchten sieht, wenn das Gesicht 
               mit einem Riesenlachen geschmückt ist, wenn man den Alltag 
               vergessen und in eine abenteuerliche Welt eintauchen kann…"
               logoPath="illugraphic"
            />
            <PartnerTile 
               title="SULGER-STIFTUNG"
               text="Die Sulger-Stiftung bezweckt in gemeinnütziger Weise die
               Förderung wohltätiger, kultureller und bildungsmässiger 
               Bestrebungen und Institutionen in Basel und der schweizerischen
               Nachbarschaft."
               logoPath="sulger-stiftung"
            />
         </div>
      </div>
   </Layout>
)