import React, { useState } from 'react'

import styles from './header.module.scss'

import YouTube from 'react-youtube'
import { getStaticImage } from '../data/files'
import { Image } from './image'
import { Link } from 'react-router-dom'

const Video = ({video, playNext}) => {
   const getYoutubeId = url => url.match(/[^/]*$/)[0]
  
   return (
      <YouTube 
         className={styles.video}
         videoId={getYoutubeId(video)}
         onEnd={playNext}
         opts={{
            width: "560",
            height: "315",
            playerVars: {
               // https://developers.google.com/youtube/player_parameters
               autoplay: 1,
               loadedVideosCount: 0,
               currentPlayingIndex: -1,
            }
         }}
      />
   )
}

// song header (independent of song)
// video header (depending on song)
export const SongHeader = ({slug, video, playNext}) => {
   const [play, setPlay] = useState(false)

   return (
      <>
      {play?
         <Video video={video} playNext={playNext}/>
         : 
         <Header>
            {video && <VideoFrame slug={slug} startVideo={() => setPlay(true)}/>}
         </Header>}
      </>  
   )
}

export const SectionHeader = ({imagePath}) => {
   return (
      <Header>
         <Image className={styles.sectionImage} 
         srcList={getStaticImage(`elements/header/${imagePath}`)} 
         alt={imagePath} />
      </Header>
   )
}


const VideoFrame = ({slug, startVideo}) => (
   <div className={styles.videoframe} >
      <div className={styles.thumbnail} >
         <Image srcList={getStaticImage('lieder-thumbnails/'+slug)} 
            alt={slug} />
      </div>
      <button onClick={startVideo} />
   </div>
)



const Header = ({children}) => (
   <div className={styles.baseheader}>
      <Link to="/">
         <Image className={styles.logo} 
            srcList={getStaticImage('elements/header/header-logo')} 
            alt="Chinder Musig Wält Logo" />
      </Link>
      {children}
   </div>
)

