import React from 'react'
import styles from './list.module.scss'
import {Link} from 'react-router-dom'


const markFirstOccurances = (arr, func) => {
   const occurred = new Set()
   return arr.map(item => {
      const isFirst = !occurred.has(func(item))
      if (isFirst) {
         item.isFirst = isFirst
         occurred.add(func(item))
      }
      return item
   })
}



export const List = ({ header, title, items, linkParams, 
   markerFunc}) => {
   
   const markedItems = (markerFunc === "firstletter")?
      markFirstOccurances(items, (item) => item.slug.charAt(0))
      : items

   return (
      <div className={styles.list}>
         {header}
         {title && <h1>{title}</h1>}
         {markedItems.map(item => 
               <Link key={item.title} 
                  to={item.to} 
                  data-first-occurance={item.isFirst}
                  {...linkParams}>
                  {item.title}
               </Link>
            )}
      </div>
   )
}
export default List
