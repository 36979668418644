import React from 'react'
import {
   BrowserRouter as Router,
   Switch, 
   Route, 
   Redirect} from 'react-router-dom'

import {
   MusicSheetsOverviewPage, 
   ColoringSheetsOverviewPage, 
   Home, 
   VideosOverviewPage, 
   RhymesOverviewPage, 
   RhymePage,
   SongPage, 
   NotFound,
   SongCategoryPage} from './pages'

import { About } from './components/about'
import { HelmetProvider } from 'react-helmet-async'

const helmetContext = {}

function App() {
   
  return (
    <div className="App">
      <Router>
         <HelmetProvider context={helmetContext}> 
               <Switch>
                  <Route exact path="/">
                     <Home />
                  </Route>

                  <Route exact path="/liedertexte">
                     <SongCategoryPage />
                  </Route>

                  <Route exact path="/liedertexte/:songCategory">
                     <SongCategoryPage />
                  </Route>

                  <Route exact path="/videos">
                     <VideosOverviewPage />
                  </Route>

                  <Route exact path="/reime-verse">
                     <RhymesOverviewPage />
                  </Route>

                  <Route exact path="/malvorlagen">
                     <ColoringSheetsOverviewPage />
                  </Route>

                  <Route exact path="/noten">
                     <MusicSheetsOverviewPage />
                  </Route>

                  <Route exact path="/lieder/:slug">
                     <SongPage />
                  </Route>

                  <Route exact path="/reime-verse/:slug">
                     <RhymePage />
                  </Route>

                  <Route exact path="/ueber-uns">
                     <About />                  
                  </Route>

                  {/* Redirects */}
                  <Route exact path="/reim/:slug" render={({ match }) => (
                     <Redirect to={`/reime-verse/${match.params.slug}`} />)}
                  />  
                  <Route exact path="/lied/:slug" render={({ match }) => (
                     <Redirect to={`/lieder/${match.params.slug}`} />)}
                  /> 
                  <Route exact path="/liedertexte">
                     <Redirect to="/lieder" />
                  </Route>
                  
                  <Route path="*" component={NotFound} status={404} />

               </Switch>
            </HelmetProvider>
       </Router>
    </div>
  )
}

export default App
