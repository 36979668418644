import React from 'react'
import styles from './layout.module.scss'
import {Link} from 'react-router-dom'
import { useRefreshAds, Ad, Wideboard } from './ads'

const NavBar = () => (
   <div className={styles.navbar}>
      <Link className={styles.songs} to="/liedertexte" aria-label="Liedertexte" />
      <Link className={styles.videos} to="/videos" aria-label="Videos" />
      <Link className={styles.rhymes} to="/reime-verse" aria-label="Reime und Verse" />
      <Link className={styles.coloring} to="/malvorlagen" aria-label="Malvorlagen" />
   </div>
)

const Footer = () => (
   <div className={styles.footer}>
      <a href="https://www.swissmom.ch"
         target="_blank" rel="noreferrer" aria-label="Swissmom"/>
      <Link to="/ueber-uns">
         Über uns
      </Link>
      <Link to="/noten">
         Noten
      </Link>

      <a href="https://www.swissmom.ch/impressum/"
         target="_blank" rel="noreferrer">
         Impressum
      </a>
      <a href="https://www.swissmom.ch/impressum/datenschutzrichtlinien/"
         target="_blank" rel="noreferrer">
         Datenschutzerklärung
      </a>
      <a href="https://www.swissmom.ch/kontakt/"
         target="_blank" rel="noreferrer">
         Kontakt
      </a>
   </div>
)


export const Layout = ({ children }) => {
   useRefreshAds()
   window.dataLayer = window.dataLayer || []
   window.dataLayer.push({'event':'pageview'})

   return (
   <div className={styles.layout}>
      <NavBar />
      <div className={styles.adcol}>
         <Ad id="adnz_halfpage_1" />
      </div>
      <div className={styles.content}>
         <Wideboard id="adnz_wideboard_1" />
         {children}
         <Wideboard id="adnz_wideboard_2" />
         <Footer />
      </div>
   </div>
)}

export default Layout
