import React from 'react'
import { Link } from 'react-router-dom'
import { Image } from './image'
import styles from './thumbnail.module.scss'


const Thumbnail = ({ title, src, to, linkParams, orient='horizontal'}) => { 

   const thumbnail = {
      horizontal: {
         0: styles.horizontal1,
         1: styles.horizontal2,
         2: styles.horizontal3
      },
      vertical: {
         0: styles.vertical1,
         1: styles.vertical2,
         2: styles.vertical3
      }
   }[orient][Math.floor(Math.random() * 3)]

   return (
   <Link className={thumbnail}
      to={to} {...linkParams}>
      <div className={styles.border}>
         <Image srcList={src} alt={title}/>
      </div>
      <span>{title}</span>
   </Link>
)}

export default Thumbnail


export const ThumbnailContainer = ({title, text, items, orient='horizontal', linkParams}) => (
   <div className={styles.container}>
      {title && <h1>{title}</h1>}
      {text && <p>{text}</p>}
      <div>
         {items.map((item) => 
            <Thumbnail key={item.to} {...item} orient={orient} linkParams={linkParams}/>
         )}
      </div>
   </div>
)
