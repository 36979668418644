export const getColoringSheet = (title) => {
   const filename = title
      .replace(/ä/g, 'ae').replace(/ö/g, 'oe').replace(/ü/g, 'ue')
      .replace(/\s/g, '-').toLowerCase()
   return `${process.env.PUBLIC_URL}/malvorlagen/${filename}.pdf`
}

export const getMusicSheet = (slug) => {
   return require(`../../media/noten/${slug}.pdf`).default || undefined
}

export const getStaticImage = (name) => {
   return ['webp', 'png'].reduce((srcList, format) => {
      try {
         const src = require(`../static/${name.replace(/\s/g, '-')}.${format}`).default
         src && srcList.push(src)
      } 
      catch (error) {
         console.log('image could not be loaded')
      }      
      return srcList
   }, []) 
}

